//
// Autocomplete
//
.suggestion {
  cursor: pointer;
  border: 1px;
  border-right: 1px solid $gray-400;
  border-left: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;
  padding-left: 10px;
  padding-right:10px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: white;
  font-weight: $font-weight-bold;
}

.suggestion:hover {
  background-color: $primary;
  color: white;
}

.suggestion-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  z-index: 2;
  overflow:auto;
  height: 160px;
  overflow-x:hidden;
}


 ///
 ///  NEW AUTOCOMPLETE
 /// 
 
.autocomplete-input {

  &.form-control-solid {
    input {
      border: none !important;
      background-color: #F3F6F9 !important;
  
      // &:focus-within {
      //   background-color: #E4E6EF !important;
      // }
    }
  }

  div:first-child {
    width: 100%;
  }

  .row {
    position: relative;
  }

  .holder-input {
    
    div:first-child {
      width: 100%;
    }
  }

  .clearBtn {
    position: absolute;
    top: 2px;
    right: 16px;
  }

  &:not(.zindex-5) {
    div:has(.autocomplete-item) {
      z-index: 1;
    }
  }
}


.autocomplete-item {
  padding: 8px;
  font-size: 14px;
  font-weight: $font-weight-bold;
  cursor: pointer;
  border-radius: 8px;

  &:hover{
    background-color: $primary;
  }
}
