.icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;    /* Adjust the width and height for the size of the circle */
    height: 50px;
    background-color: $primary; /* Add semi-transparent black background */
    border-radius: 50%;  /* Make it a perfect circle */
    color: white;   /* Change the arrow color */
}

.custom-carousel-indicators .carousel-indicators li {
    width: 15px;
    height: 15px;
    border-radius: 60%;
    background-color: gray;
}

.custom-carousel-indicators .carousel-indicators .active {
    background-color: $primary;
}

.carousel-control-prev, .carousel-control-next {
    width: min-content;
}