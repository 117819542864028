//
// Override global variables
//

// Theme colors
$primary:       									#1BC5BD;
$primary-hover:    									#0BB7AF;
$primary-light:    									#C9F7F5;
$primary-inverse:  									#FFFFFF;

$info:       									    #6993FF;
$info-light:    									#E1E9FF;
$info-hover:    									#4A7DFF;
$info-inverse:  									#FFFFFF;

$success:       									#3699FF;
$success-hover:    									#187DE4;
$success-light:    									#E1F0FF;
$success-inverse:  									#FFFFFF;

$text-muted: #a1a5b7;

$card-box-shadow: 0px 10px 35px 0px rgba(56,71,109,.075);

$table-cell-padding: 1rem;

$component-active-bg: $primary;
$component-active-color: $primary-inverse;

$form-check-input-checked-color:          $component-active-color;
$form-check-input-checked-bg-color:       $component-active-bg;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color;

.gap-2{
    gap: 8px;
}
